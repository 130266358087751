'use client';
import React, { useCallback, useEffect } from "react"
import { useDeviceSelectors } from "react-device-detect";
import * as Dialog from '@radix-ui/react-dialog';
import Image from "next/image";
import Cookies from 'js-cookie';
import { useRouter } from "next/navigation";

const OpenInRow: React.FC<{
    icon: React.ReactNode;
    text: string;
    buttonLabel: string;
    buttonHandler: React.MouseEventHandler<HTMLButtonElement> | undefined;
    buttonColor?: string;
}> = (props) => {
    const { icon, text, buttonLabel, buttonHandler, buttonColor } = props;
    return (
        <div className="flex flex-row text-white items-center">
            {/* <div className="flex-shrink-0 w-16 h-16 bg-green-500"></div> */}
            {icon}
            <div className="flex-grow px-3">
                <span className="text-md font-semibold">{text}</span>
            </div>
            <button className="py-3 px-8 bg-blue-1/100 rounded-full max-w-[104px] flex justify-center" style={{
                backgroundColor: buttonColor
            }} onClick={buttonHandler}>{buttonLabel}</button>
        </div>
    )
}

const OpenMobileApp: React.FC = (props) => {
    const router = useRouter();
    const [openBanner, setOpenBanner] = React.useState(false);
    const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);

    const { isIOS, isAndroid } = selectors || {};

    useEffect(() => {
        setOpenBanner(isIOS || isAndroid);
    }, [isIOS, isAndroid]);

    const handleContinueButton = useCallback(() => {
        setOpenBanner(false);
    }, [setOpenBanner]);

    const handleRedirectButton = useCallback(() => {
        const meetingSession = Cookies.get('meeting-session');
        // https://reframe.onelink.me/4YhD?af_xp=custom&pid=meetings&c=meetings&deep_link_value=meeting&deep_link_sub1=meeting_id
        const redirectLink = `https://reframe.onelink.me/4YhD?af_xp=custom&pid=meetings&c=meetings&deep_link_value=meeting&deep_link_sub1=${meetingSession}`;
        if (redirectLink) {
            router.push(redirectLink);
        }
    }, [])

    return (
        <Dialog.Root open={openBanner} >
            <Dialog.Portal>
                <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0 z-[99]" />
                <Dialog.Content className="bg-[#181C1F] data-[state=open]:animate-slideUp data-[state=closed]:animate-slideDown fixed bottom-0 left-0 right-0 flex flex-col gap-4 text-white px-6 py-3 z-[100]">
                    <h1 className="text-center text-lg font-bold">Open Reframe Meeting in...</h1>
                    <OpenInRow 
                        icon={
                            <Image className="rounded-xl" src="/images/ReframeAppIcon.svg" alt="Reframe App Icon" width={48} height={48} />
                        }
                        text="Reframe App"
                        buttonLabel="Open"
                        buttonHandler={handleRedirectButton}
                    />
                    {
                        isIOS && (
                            <OpenInRow
                                icon={
                                    <Image className="rounded-xl" src="/images/SafariIcon.png" alt="Safari App Icon" width={48} height={48} />
                                }
                                text="Safari"
                                buttonLabel="Continue"
                                buttonHandler={handleContinueButton}
                                buttonColor="#2A3236"
                            />
                        )
                    }
                    {
                        isAndroid && (
                            <OpenInRow
                                icon={
                                    <div className="w-12 h-12 p-2 rounded-xl flex justify-center items-center bg-white">
                                        <Image className="rounded-xl" src="/images/ChromeIcon.png" alt="Chrome App Icon" width={40} height={40} />
                                    </div>
                                }
                                text="Default Browser"
                                buttonLabel="Continue"
                                buttonHandler={handleContinueButton}
                                buttonColor="#2A3236"
                            />
                        )
                    }
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

export default OpenMobileApp
